import * as React from 'react'
import Alert from 'react-s-alert'
import { navigate } from 'gatsby'

import Sticky from '@littlestores/common/components/Sticky/Sticky'
import decorateWithOrdersContext from '../../hoc/OdersHOC'

import {CHECKOUT} from './../../constants/paths'

import CartIcon from './images/supermarket.svg'
import styles from './ViewCartFooter.module.scss'
import Button from '@littlestores/common/components/Button'
import Layout from "@littlestores/common/components/Layout";

function ViewCartFooter({ordersContext}) {
  const totalUnits = ordersContext
    .orders
    .filter(orderItem => orderItem.units > 0 && !orderItem.outOfStock)
    .reduce((acc, orderItem) => acc + orderItem.units, 0)

  const stocks = ordersContext.stock?.length > 0

  return (
    stocks && totalUnits > 0 ? (
      <Sticky bottom="0px" className={styles['CartIcon']}>
        <Layout className={styles.CartLayout}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div onClick={() => { ordersContext.saveOrders(); Alert.success('Saved orders for later. Your order items will remain when you visit next time.') }} className={styles['Save']}>
                    <span className={styles['CartItems']}>{totalUnits}</span>
                    <span className={styles['ImgContainer']}><img src={CartIcon} height="50" width="50"/></span>
                </div>
                <Button className={styles['Cart-button']} onClick={() => navigate(CHECKOUT)}>Goto Cart</Button>
            </div>
        </Layout>
      </Sticky>
    ) : null
  )
}

export default decorateWithOrdersContext(ViewCartFooter)
