import React from 'react'
import img from "./images/menu.svg";
import Styles from './HamburgerButton.module.scss'

const HamburgerButton = ({onClick}) => {
    return(
      <div className={Styles.HamburgerButton} onClick={onClick}>
          <img src={img}/>
      </div>
    )
}

export default HamburgerButton
