import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import SearchIcon from './images/search.svg'

import styles from './Search.module.scss'

export default function Search({ caps, onChange, className, theme, type, placeholder, ...others }) {
  const inputRef = React.useRef(null)
  
  const componentClassNames = classNames(
    'Input',
    styles.this,
    caps && styles['this---caps'],
    theme && styles[`this---${theme}`]
  )

  const scrollToAndFocus = () => {
    inputRef.current.focus()
  }

  return (
    <div className={classNames(styles['Search-Container'], className)} >
      <input {...others} ref={inputRef} type={type} className={componentClassNames} placeholder={placeholder} onClick={scrollToAndFocus} onChange={onChange}/>
      <img src={SearchIcon} className={styles['Search-Icon']} onClick={scrollToAndFocus}/>
    </div>
  )
}

Search.propTypes = {
  caps: PropTypes.bool,
  className: PropTypes.string,
  inputRef: PropTypes.func,
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
  type: PropTypes.oneOf(['email', 'password', 'tel', 'text', 'textarea']),
}

Search.defaultProps = {
  placeholder: 'Search items here...',
  theme: 'light',
  type: 'text',
}
