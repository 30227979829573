import React, {useEffect} from 'react'
import Drawer from "@material-ui/core/Drawer";
import decorateWithOrdersContext from "../../hoc/OdersHOC";
import Styles from './SideDrawer.module.scss'
import {makeStyles} from "@material-ui/core/styles";
import LittleStoresImg from './Images/littlestores 2.png'
import StoreLoc from '../Checkout/images/addLine.svg'
import StorePhone from './Images/whatsapp.svg'
import StoreEmail from './Images/mail.svg'
import CloseIcon from './Images/close.svg'
import DrawerItem from "./DrawerItem/DrawerItem";
const SideDrawer = ({anchor,show, onClose, ordersContext}) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        drawerPaper: {
            minWidth:260,
            maxWidth:'75%',
        },

    }));
    const classes = useStyles()
    const email = ordersContext.storeDetails?.email
    const phone = "+"+ordersContext.storeDetails?.dialing_code + ordersContext.storeDetails?.mobile

    return(
        <Drawer anchor={anchor} open={show} onClose={onClose} classes={{
            paper: classes.drawerPaper,
        }}>
            <div className={Styles.SideDrawerContainer}>
                <div className={Styles.SideDrawer}>
                    <h5>Store Details</h5>
                    <img src={CloseIcon} onClick={onClose}/>
                    <DrawerItem icon={null} text={ordersContext.shopName}/>
                    <DrawerItem icon={StoreLoc} text={ordersContext.address.line + ', ' + ordersContext.address.pincode}/>
                    <DrawerItem icon={StorePhone} text={phone} link={`https://api.whatsapp.com/send?phone=${phone}`}/>
                    <DrawerItem icon={StoreEmail} text={email} link={`mailto:${email}`}/>

                </div>
                <footer>
                    <p>Powered by</p>
                    <a href={'https://littlestores.in'} target={'_blank'} rel={'noreferrer'}>
                        <img src={LittleStoresImg}/>
                    </a>
                </footer>
            </div>
        </Drawer>
    )

}

export default decorateWithOrdersContext(SideDrawer)
