import React from 'react'
import Styles from './DrawerItem.module.scss'

const DrawerItem = ({icon, text, link = null}) => {
    return (
        <div className={Styles.DrawerItem}>
            {icon && <img src={icon}/>}
            {link ? <a href={link} target={'_blank'} rel={'noreferrer'}>{text}</a> : <p>{text}</p>}
        </div>
    )
}

export default DrawerItem
