import React, {useState} from 'react'
import Styles from './AddFilter.module.scss'
import FilterPill from "./FilterPill/FilterPill";


const AddFilter = ({categories, setFilter,currentFilter}) => {
    const handleChange = categoryId => {
        categoryId ? setFilter(categoryId) : setFilter('')
    }
    return (
        <div className={Styles.AddFilter}>
            <FilterPill
                categoryName={'All'}
                id={''}
                currentId={currentFilter}
                onClick={() => handleChange('')}
            />
            {categories && categories.map(item => {
                return <FilterPill
                    key={item.id}
                    id={item.id}
                    currentId={currentFilter}
                    categoryName={item.name}
                    onClick={() => handleChange(item.id)}
                />
            })}
        </div>
    )
}

export default AddFilter
