import * as React from 'react'
import Alert from 'react-s-alert'

import Layout from '@littlestores/common/components/Layout'
import Title from '@littlestores/common/components/Title'
import Search from '@littlestores/common/components/Search'
import Sticky from '@littlestores/common/components/Sticky/Sticky'


import ViewCartFooter from '../ViewCartFooter'
import Card from '../Card'

import EmptyResult from '../../images/no-result.svg'

import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css'
import styles from './Home.module.scss'
import './Home.scss'

import decorateWithOrdersContext from '../../hoc/OdersHOC'
import AddFilter from "../AddFilter/AddFilter";
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import SideDrawer from "../SideDrawer/SideDrawer";
import CircularProgress from "@material-ui/core/CircularProgress";
import SEO from "../seo";

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.timeout = 0;
    }

    state = {
        data: [],
        search: '',
        visible: true,
        showDrawer: false
    }

    componentDidMount() {
        window.scrollTo(0, 0)

    }


    render() {
        const {
            search,
            visible,
            showDrawer,
        } = this.state

        const data = this.props.ordersContext.stock
        const error = this.props.ordersContext.error
        const nextData = this.props.ordersContext.nextStock
        const searchItems = this.props.ordersContext.getSearchedItems
        const {shopName} = this.props.ordersContext
        const noData = data?.length === 0
        const categories = this.props.ordersContext.categories
        const setFilters = this.props.ordersContext.setFilters
        const currentFilter = this.props.ordersContext.currentFilter


        const onSearch = (event) => {
            const query = event.target.value.toLowerCase().trim(' ')
            if (this.timeout)
                clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.setState({search: query})

                searchItems(query)
            }, 500)

        }

        return (
            <>
                <SEO title={`${shopName}`}/>
                <Alert effect="stackslide" position="top" timeout={3000}/>
                <SideDrawer anchor={"left"} show={showDrawer} onClose={() => this.setState({showDrawer: false})}/>
                <Layout
                    className={styles.ScrollDivOffSet}
                    visibilityChange={(visible) => this.setState({visible})}
                    options={{threshold: [0, 0.1]}}
                >
                </Layout>
                {!error && <Sticky top="0px" stick={!visible}>
                    <Layout className={styles.Header}>
                        <HamburgerButton onClick={() => this.setState({showDrawer: true})}/>
                        <Title className={styles['Title']}>{shopName}</Title>
                    </Layout>
                    <Layout className={styles['SearchLayout']}>
                        <Search onChange={onSearch}/>
                    </Layout>
                    <Layout className={styles['FilterLayout']}>
                        <AddFilter setFilter={setFilters} categories={categories} currentFilter={currentFilter}/>
                    </Layout>

                </Sticky>}
                <Layout className={styles['LayoutWithBottomPadding']}>
                    {!error &&
                    <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                        {
                            data?.map(orderItem =>
                                <Card
                                    key={orderItem.id}
                                    {...orderItem}
                                />)
                        }
                    </div>}
                    <div className={styles.PaginationSection}>
                        {nextData?.links.next ?
                            <Layout
                                visibilityChange={(visible) => {
                                    visible && this.props.ordersContext.setNextCurrentPage()
                                }}
                                options={{threshold: 1.0}}
                            >
                                <CircularProgress/>
                            </Layout> :
                            <p style={{fontWeight: "light", letterSpacing: "1px"}}>
                                {!noData ? "That's all for now" : ""}
                            </p>
                        }
                    </div>
                    {
                        search.trim() !== '' && noData && !error && (
                            <div style={{position: 'relative', height: "600px"}}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    No results found!
                                </div>
                                <img src={EmptyResult} width="100%"
                                     style={{marginBottom: '0px', position: 'absolute'}}/>
                            </div>
                        )
                    }

                    {
                        search === '' && noData && !error && (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <CircularProgress/>

                            </div>
                        )
                    }
                </Layout>
                {!error && <ViewCartFooter/>}
                {error &&
                <div style={{textAlign: "center",padding:"12px"}}>
                    <h3 style={{letterSpacing: "1px", fontWeight:"normal"}}>Something went wrong! Please try again later</h3>
                </div>}
            </>
        )
    }
}

export default decorateWithOrdersContext(Home)
