import * as React from 'react'
import classNames from 'classnames'
import {Img} from 'react-image'
import ReactTooltip from "react-tooltip";

import Shave from '@littlestores/common/components/Shave'
import AddWidget from '@littlestores/storekeeper-microsite/src/components/AddWidget'

import decorateWithOrdersContext from '../../hoc/OdersHOC'

import InfoIcon from './images/info.svg'
import NoImage from './images/NoImg.jpg'

import './Shine.scss'
import styles from './Card.module.scss'

function Card({imgUrl, title, outOfStock, quantity, description, price, id, ordersContext, isCheckout, ...props}) {
    const [showDescription, setShowDescription] = React.useState(false)
    const updateOrder = (units) => {
        ordersContext.updateOrders({...props, imgUrl, title, outOfStock, quantity, description, price, id, units})
    }
    const ref = React.useRef(null)

    const units = ordersContext.orders.find(orderItem => orderItem.id === id)?.units

    React.useEffect(() => {
        if (outOfStock && units) {
            updateOrder(0)
        }
    }, [])

    return (
        <div className={classNames(styles['Container'], isCheckout && styles['Container--checkout'])}>
            {
                !isCheckout && (
                    <div
                        className={classNames(styles['Image-Container'], 'shine', isCheckout && styles['Image-Container--checkout'])}>
                        {
                            showDescription &&
                            <div className={styles['Description']}

                            >{description}</div>
                        }
                        {
                            outOfStock && (
                                <>
                                    <div className={styles['NoStock']}>Sold out</div>
                                    <div className={styles['Fade']}/>
                                </>
                            )
                        }

                        <Img src={imgUrl || NoImage}
                             loader={<div className={classNames(styles['Image-Container'], 'shine')}/>}/>

                    </div>
                )
            }
            <div style={{padding: '12px', width: isCheckout ? '100%' : undefined, borderTop: '1px solid lightgray'}}>
                <div className={styles['Title']} ref={ref} data-for={id.toString()} data-tip={title}>
                    <Shave
                        maxHeight={20}>{title}
                    </Shave>
                </div>
                <ReactTooltip className={styles.ToolTip} multiline place="top" effect="solid" id={id.toString()}/>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '8px'
                }}>
                    {
                        !isCheckout &&
                        <div className={styles['Help']}>
                            <img height="18" width="18" src={InfoIcon}
                                 onClick={() => {
                                     ReactTooltip.show(ref);
                                     setShowDescription(prevState => !prevState)
                                 }}

                            />
                        </div>
                    }
                    {
                        quantity && <div className={styles['Quantity']}>Quantity: {quantity}</div>
                    }
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <span className={styles['Price']}>{price.split('.')[0]} {isCheckout && ' each'}</span>
                    <span style={{float: 'right'}}>
            <AddWidget onChange={updateOrder} disabled={outOfStock} units={units}/>
          </span>
                </div>
                {
                    isCheckout &&
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '12px'}}
                         className={styles['Price']}>
                        <span>{`${price} x ${units} = ₹${price * units}`}</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default decorateWithOrdersContext(Card)
