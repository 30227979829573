import React from 'react'
import Styles from "./FilterPill.module.css";

const FilterPill = ({categoryName,onClick,currentId,id}) => {
    const styles = id===currentId?`${Styles.FilterPill} ${Styles.FilterPillActive}`:Styles.FilterPill
    return(
        <div className={styles} onClick={onClick}>
            <p>{categoryName}</p>
        </div>
    )
}

export default FilterPill
